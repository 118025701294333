<template>
  <div class="my-upload" :class="isMax?'is_max':''">
    <div
      class="my-upload-list"
      style="ling-height:42px;"
      v-for="(item, index) in uploadList"
      :key="'upload' + index"
    >
      <template>
        <div
          class="video_wrap"
          v-if="fileType=='video'"
        >
          <div class="video_mask">
            <i class="iconfont icon-shipinmianxing"></i>
          </div>
          <img :src="item.cover" v-if="item.cover&&item.cover!='none'" alt="">
          <img src="@/assets/img/empty.png" v-else alt="">
        </div>
         <img
          :src="item.url"
          v-if="fileType=='image'"
        />
        <div class="my-upload-list-cover" style="line-height: initial;">
          <Icon type="ios-eye-outline" @click.native="handleView(item)"></Icon>
          <Icon type="ios-trash-outline" @click.native="handleRemove(item)" ></Icon>
        </div>
      </template>
    </div>
    <Upload
        :multiple="false"
        :show-upload-list="false"
        :max-size="fileMax"
        :accept="typeAccept"
        :headers="formHeaders"
        :before-upload="handleBeforeUpload"
        type="select"
        :action="uploadUrl"
        v-show="fileType=='video'?uploadList && uploadList.length <1:uploadList && uploadList.length < 5"
        style="display: inline-block;"
        :disabled="uploading"
      >
        <div class="up_box" :class="!uploading?'no_up':''" >
          <Spin fix v-if="uploading">
              <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
          </Spin>
          <div  class="up_add">
            +
            <p style="text-align:center;">上传</p>
          </div>
        </div>
    </Upload>
    <!-- <input @change="upVideo" accept="video/mp4" class="upload-video" ref="upload-video" type="file" />
      <div style="width:42px;height:42px;color:rgb(133, 133, 133);display: flex;align-items: center;justify-content: center;" v-if="!disabled">
          <div style="font-size:12px;">
            +
            <p style="font-size:12px;text-align:center;">上传 </p>
          </div>
        </div> -->
    <!-- <p class="upload-p" style="width: 100%;font-size: 12px;color: #4d4d4d;">{{config.remind}}</p>
    <p
      class="upload-p"
      style="width: 100%;font-size: 12px;color: #4d4d4d;"
      v-if="config.tip"
    >{{config.tip}}</p> -->
    <iModal title="预览" cancel-text v-model="visible">
      <div style="text-align: center;">
        <img :src="imgName.url" v-if="fileType=='image'" style="max-width: 100%" />
        <video
          :src="imgName.url"
          :poster="imgName.cover"
          controls="controls"
          v-if="fileType=='video'"
          style="max-width: 100%"
        >您的浏览器不支持 video 标签。</video>
      </div>
    </iModal>
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import OSS from "ali-oss";
import * as ObsClient from "esdk-obs-browserjs";
import config from "../../../static/config.json";
export default {
  props: {
    fileList: {
      default: () => {
        return [];
      },
    },
    disabled: {
      default() {
        return false;
      }
    },
    isMax: {
      default() {
        return false;
      }
    },
  },
  data() {
    return {
      imgName: "",
      visible: false,
      uploadList: [],
      buttonShowed: true,
      uploading:false,
      fileType:'all',
      maxSize:100,
      fileMax:1024 * 100,
    };
  },
  computed: {
    ...mapGetters({
      formHeaders: "getFormHeaders",
      uploadUrl: "getUploadUrl",
    }), 
     typeAccept() {
      return this.fileType == "all" ? "image/*,video/*" : this.fileType == "image" ? "image/*" : "video/*";
    },
  },
  methods: {
    ...mapActions({
    }),
    editType(type){
      this.fileType=type;
    },
    // 查看上传
    handleView(name) {
      this.imgName = name;
      this.visible = true;
    },
    // 移除上传
    handleRemove(file) {
      const fileList =this.uploadList; 
      this.uploadList.splice(fileList.indexOf(file), 1);
      if (!this.uploadList.length) {
        this.fileType='all'
      }
      this.$emit("upload-ok", this.outList());
    },
    outList() {
      //传出数据
      let list = [];
      for (const item of this.uploadList) {
        if (item.cover) {
          const opt = {
            url: item.url,
            cover: item.cover,
          };
          list.push(opt);
        } else {
          list.push(item);
        }
      }
      console.log(list,'list')
      return list;
    },
    // 文件格式错误
    handleFormatError() {
      this.$Notice.warning({
        title: "图片或视频格式不对",
      });
    },
    // 上传失败
    handleUploadError() {
      this.$Message.warning({
        content: "您的网络请求已超时,请重新上传",
        duration: 3,
        closable: true,
      });
    },
    // 文件过大
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件大小超过限制",
        desc: `请上传小于${this.maxSize}的文件`,
      });
    },
    // 上传数量
    handleBeforeUpload(file) {
      console.log(file,'eee');
      if (this.uploadList && this.uploadList.length) {
        if (!file.type.includes(this.fileType)) {
          this.$Notice.warning({
            title: "图片视频不能同时上传",
          });
          return false;
        }
      }
      const isImgVideo =file.type.includes('image')  || file.type.includes("video");
      if (!isImgVideo) {
        this.handleFormatError()
        return false;
      }
      if (file.size > 1024 * 10000 * 10) {
        this.handleMaxSize();
        return false;
      }
      this.uploading=true;
      if(file.type.includes('image')){
        this.upFileImg(file);
      }else{
        this.upFileVideo(file)
      }
      return false
    },
    videoName(str) {
      // 返回随机视频名 /exshow/videos/年月/时间戳+5位随机数.mp4
      const year = new Date().getFullYear();
      var month = new Date().getMonth();
      const time = new Date().getTime();
      if (month < 9) {
        month = "0" + (month + 1);
      } else {
        month = month + 1;
      }
      const prefix = "/showonline/video";
      const random = this.randomn(5);
      const name = `${prefix}/${year}${month}/${time}${random}.${str}`;
      return name;
    },
    randomn(n) {
      // 返回随机数 n是取值几位数
      let res = "";
      for (
        ;
        res.length < n;
        res += Math.random()
          .toString(36)
          .substr(2)
          .toUpperCase()
      ) {}
      return res.substr(0, n);
    },
    upVideo(file){
      const str = file.name.split(".")[1];
      let client = new OSS({
        region: "cn-shanghai",
        accessKeyId: "LTAI5tHdipkqKVNJmDJ6vaJ4",
        accessKeySecret: "9tu7VxQ7iBFDFO79BCoK8nGbIZKqSO",
        endpoint: "oss-cn-shanghai.aliyuncs.com",
        bucket: "huizhanren",
      });
      let that = this;
      const storeAs = this.videoName(str);
      client
        .multipartUpload(storeAs, file, {})
        .then(function(result) {
          console.log(result, "result-----");
          const url = result.res.requestUrls[0].split("?")[0];
          if (url) {
            that.fileType = "video";
            that.uploadList = [];
            that.uploadList[0] = {
              url: url,
              cover: `${url}?x-oss-process=video/snapshot,t_5000,f_jpg,w_0,h_0,m_fast`,
            };
            that.$emit("upload-ok", that.outList());
          }
          that.uploading=false;
        })
        .catch(function(err) {
          that.uploading=false;
          that.handleUploadError();
        });
    },
    upVideo2(file){
      const str = file.name.split(".")[1];
      let obsClient = new ObsClient({
        access_key_id: config.obs.access_key_id,
        secret_access_key: config.obs.secret_access_key,
        server: config.obs.server,
      });
      let that = this;
      let storeAs = this.videoName(str);
      storeAs = storeAs.replace("/", "");
      obsClient.putObject(
        {
          Bucket: "synairsoft",
          Key: storeAs, // 存储在obs的文件。可写成file/name即会生成file文件夹name文件
          SourceFile: file, // 文件上传使用sourceFile文本上传采用Body
          // ACL: true, // 如果设置ACL即为公共访问。不需要认证如果不设置此项ACL，则需要先通过文件下载在进行路径访问
        },
        function(err, result) {
          console.log(result, "result-----");
          if (err) {
            that.uploading=false;
            that.handleUploadError();
          } else {
            const url = config.obs.url + storeAs;
            if (url) {
              that.fileType = "video";
              that.uploadList = [];
              that.uploadList[0] = {
                url: url,
                cover: `none`,
              };
              that.$emit("upload-ok", that.outList());
            }
            that.uploading=false;
          }
        }
      );
    },
    upFileVideo(file){
      if (config.https.uploadType === "obs") {
        this.upVideo2(file);
      } else {
        this.upVideo(file);
      }
    },
    upFileImg(file) {
      let data = new FormData();
      data.append("multfile", file);
      data.append("operaType", null);
      // this.$dialog.loading.open();
      this.$http
        .post("https://online-api.zhanshangxiu.com/api/v1/file", data)
        .then((res) => {
          // this.Upimg.push(res.data.Data.imgUrls[0])
          console.log(res, "upFie");
          if (res.data.data) {
            let opt = {
              url: res.data.data[0],
            };
            this.fileType='image'
            this.uploadList.push(opt)
            console.log(this.uploadList,'list')
            this.$emit("upload-ok", this.outList());
          }
          this.uploading=false;
        })
        .catch((err) => {
          this.uploading=false;
          this.handleUploadError();
        });
    },
    initUploadList() {
      this.uploadList.splice(0, this.uploadList.length);
      for (let item of this.fileList) {
        if (item.cover) {
          this.uploadList.push({
            url: item.url,
            cover: item.cover,
          });
        } else {
          this.uploadList.push({
            url: item.url,
          });
        }
      } 
    },
    clearFiles() {
      this.uploadList  = [];
    },
  },
  watch: {
    fileList() {
      this.initUploadList();
    },
  },
  mounted() {
    this.initUploadList();
  },
  components: {
  
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
.my-upload{
  line-height: 1.5;
  &.is_max{
    .up_box{
      width: 100px;
      height: 100px;
      .up_add{
        font-size: 14px;
        p{
          font-size: 14px;
        }
      }
    }
    .my-upload-list{
      width: 100px;
      height: 100px;
      >img{
        width: 100px;
        height: 100px;
      }
      .video_wrap{
        width: 100px;
        height: 100px;
        .video_mask{
          i{
            font-size: 24px;
          }
        }
      }
      .my-upload-list-cover{
        i{
          font-size: 18px;
        }
      }
    }
  }
  .up_box{
    width:42px;
    height:42px;
    color:rgb(133, 133, 133);
    cursor: pointer;
    user-select: none;
    border:1px dashed #dcdee2;
    position: relative;
    border-radius:4px;
    .up_add{
      font-size: 12px;
      p{
        font-size: 12px;
      }
    }
    &.no_up{
      &:hover{
        border:1px dashed #2d8cf0
      }
    }
    .up_add{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;

    }
  }
  .my-upload-list{
    margin-right: 10px;
    >img{
      width: 42px;
      height: 42px;
      display: block;
    }
    .video_wrap{
      width: 42px;
      height: 42px;
      position: relative;
      .video_mask{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000000, $alpha: 0.3);
        color: #fff;
        i{
          font-size: 14px;
        }
      }
      img{
        width:100%;
        height: 100%;
        display: block;
      }
    }
    .my-upload-list-cover{
      align-items: center;
      justify-content: center;  
      display: flex;
      opacity: 0;
      i{
        font-size: 12px;
      }
      &:hover{
        opacity: 1;
        
      }
    }
  }
}
</style>