<template>
  <iModal :mask-closable="false" @on-cancel="cancel" footer-hide title="免费发布供需" v-model="showNeeds" width="640">
    <div class="admin-add">
      <div class="admin-add-content">
        <Form :label-width="100" @submit.native.prevent label-position="right">
          <FormItem label="供需类型：">
            <div class="btnwrap">
              <Button :class="activeShow == index ? 'primary' : ''" :key="index" @click="changeCategory(index, item)" class="btn" v-for="(item, index) in typeTabs">{{ item }}</Button>
            </div>
          </FormItem>
          <FormItem label="采购产品：">
            <Input v-if="activeShow === 0" :placeholder="`请添加您想${activeShow === 0 ? '采购' : '供应'}的产品`" v-model="productName" />
            <Select class="select_wrap" v-model="productName" v-else placeholder="请选择您需要供应的产品">
              <Option v-for="item in proList" :value="item.name" :key="item.name">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="供需内容：" style="margin-bottom: 0;">
            <Input :rows="4" :placeholder="`请详细描述您需要${activeShow === 0 ? '采购' : '供应'}的产品`" style="margin-bottom:20px;" type="textarea" v-model="content" />
          </FormItem>
          <FormItem label="上传文件：">
            <div>
              <uploadNeed ref="uploadNeed" :fileList="uploadBanner" @upload-ok="bannerOK" :isMax="true" />
            </div>
            <p class="upload-p" style="width: 100%;font-size: 12px;color: #4d4d4d;">(最多上传5张图片或1个视频)</p>
          </FormItem>
        </Form>
      </div>
      <div class="f-tac">
        <Button @click="cancel" style="margin-right: 20px;width: 130px">取消</Button>
        <Button @click="handleSubmit" style="width: 130px;" type="primary">发布需求</Button>
      </div>
    </div>
  </iModal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import uploadNeed from "@components/upLoad/upLoadNeed";
export default {
  data() {
    return {
      showTabs: "1",
      showNeeds: false,
      typeTabs: [
        "采购产品",
        "供应产品",
        // '征求服务'免费发布供需
      ],
      activeShow: 0,
      selectTab: "",
      content: "",
      productName: "",
      uploadBanner: [], // 活动图
      proList: [],
      config: {
        fileType: "img",
        headers: {},
        action: "",
        format: ["jpg", "jpeg", "png"],
        "max-size": 2048,
        remind: "(图片要求：2M以下的jpg或png，最多上传5张)",
        limitNum: 5,
      },
      editModel: false,
      editId: "",
    };
  },
  computed: {
    ...mapGetters({
      formHeaders: "getFormHeaders",
      uploadUrl: "getUploadUrl",
      userInfo: "getUser",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      login: "getLogin",
    }),
    uploadBannerConfig() {
      let config = JSON.parse(JSON.stringify(this.config));
      config.id = "upload_1";
      config.headers = this.formHeaders;
      config.action = this.uploadUrl;
      config.remind = "(图片要求：2M以下的jpg或png，最多上传5张)";
      return config;
    },
    isRegOk() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    userRole() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole ? this.userInfo.inMember.userRole : "";
    },
  },
  components: {
    uploadNeed,
  },
  created() {
    if (this.userRole == "EXHIBITOR") {
      this.getProduct();
    }
  },
  methods: {
    ...mapActions({
      supplyDemandSubmit: "news/supplyDemand",
      editSupply: "news/editSupply",
      unverifiedDialog: "unverifiedDialog",
      graphqlPost: "graphqlPostByZXS",
    }),
    open(item = null) {
      if (!(this.userInfo && this.userInfo.userId && this.userInfo.phone) || this.userInfo.inMember.userRole == "VISITOR") {
        this.unverifiedDialog(this);
        return;
      }
      this.showNeeds = true;
      if (item) {
        // 编辑模式
        this.editModel = true;
        this.editId = item.id;
        console.log(item, "itemitemitem");
        if (item.imgUrls && item.imgUrls.length) {
          let arr = [];
          item.imgUrls.forEach((e) => {
            arr.push({
              url: e,
            });
          });
          this.uploadBanner = arr;
          this.$nextTick(() => {
            this.$refs.uploadNeed.editType("image");
          });
        } else if (item.videos && item.videos.length) {
          this.uploadBanner = [item.videos[0]];
          this.$nextTick(() => {
            this.$refs.uploadNeed.editType("video");
          });
        }
        this.selectTab = item.type;
        this.typeTabs.forEach((c, i) => {
          if (c == this.selectTab) {
            this.activeShow = i;
          }
        });
        this.content = item.content;
        this.productName = item.productName;
      }
    },
    bannerOK(list) {
      this.uploadBanner = list;
    },
    async handleSubmit() {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if (!this.content) {
        this.$Message.error("请输入供需内容");
        return;
      }
      if (!this.productName) {
        if (this.activeShow == 0) {
          this.$Message.warning("请添加您想采购的采购产品");
        } else {
          this.$Message.warning("请选择您想采购的采购产品");
        }
        return;
      }
      if (!this.editModel) {
        this.content = this.content.split("\n").join("<br>");
        // 新增模式
        let opt = {
          memberId: this.userInfo.id,
          content: this.content,
          productName: this.productName,
          publisherType: 0,
          originName: this.exhibitionInfo.name || "平台",
          exhibitionId: this.exhibitionId,
          type: this.selectTab || "采购产品",
        };
        if (this.uploadBanner.length) {
          if (this.uploadBanner[0].cover) {
            opt.videos = `[{"cover":"${this.uploadBanner[0].cover}","url":"${this.uploadBanner[0].url}","duration":"","coverIndex": 5}]`;
          } else {
            let arr = [];
            this.uploadBanner.forEach((e) => {
              arr.push(e.url);
            });
            opt.imgUrls = arr.join(";");
          }
        }
        let data = await this.supplyDemandSubmit(opt);
        if (data && data.result) {
          this.$Message.success("提交成功");
          this.$emit("finish");
        } else {
          this.$Message.error(data.message);
        }
        this.cancel();
      } else {
        // 编辑模式
        let opt = {
          id: this.editId,
          content: this.content,
          productName: this.productName,
          auditState: 0,
          type: this.selectTab || "采购产品",
        };
        if (this.uploadBanner.length) {
          if (this.uploadBanner[0].cover) {
            opt.videos = `[{"cover":"${this.uploadBanner[0].cover}","url":"${this.uploadBanner[0].url}","duration":"","coverIndex": 5}]`;
            opt.imgUrls = "";
          } else {
            let arr = [];
            this.uploadBanner.forEach((e) => {
              arr.push(e.url);
            });
            opt.imgUrls = arr.join(";");
            opt.videos = "";
          }
        } else {
          opt.imgUrls = "";
          opt.videos = "";
        }
        let res = await this.editSupply(opt);
        if (res.result) {
          this.$Message.success("提交成功");
          this.$emit("finish");
        } else {
          this.$Message.error(res.message);
        }
        this.cancel();
      }
    },
    async getProduct() {
      if (this.userRole == "EXHIBITOR") {
        let query = `
        query{
          exhibitorQuery{
            get(id:"${this.userInfo.inMember.exhibitorId}"){
                products(num:10000){
                  name
                  id
                  nameEn 
                }
              }
            }
        }
      `;
        let opt = {
          query: query,
          variables: {},
        };
        let res = await this.graphqlPost(opt);
        console.log(res, "exinfo res");
        this.proList = res.data.exhibitorQuery.get.products;
      }
    },
    cancel() {
      this.showNeeds = false;
      this.content = "";
      this.productName = "";
      this.uploadBanner = [];
      this.showTabs = "1";
    },
    changeCategory(index, item) {
      if (this.typeTabs[index] === "供应产品" && this.userRole !== "EXHIBITOR") {
        this.$Message.warning("仅展商可发布供应信息!");
        return;
      }
      this.productName = "";
      this.activeShow = index;
      this.selectTab = item;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.btn {
  margin-right: 12px;
  width: 110px;
  &:last-child {
    margin-right: 0;
  }
}
.primary {
  color: #fff;
  @include background_color(#2d8cf0);
  @include border_color(#2d8cf0);
}
.admin-add-content {
  padding-right: 30px;
}
.btnwrap {
  display: flex;
  button {
    flex: 1;
    background-color: #f7f7f7;
  }
}
body .admin-add .ivu-btn.ivu-btn-default.primary:hover {
  color: #fff !important;
}
.select_wrap {
  &::v-deep .ivu-select-dropdown {
    width: 268px;
  }
}
</style>
